import menu from './components/main-menu/menu';
import toxicSlider from './components/toxic-slider/toxic-slider';

$svjq(function() {
	const footerEl = $svjq('footer');
	const footerBottomPos = footerEl.position().top + footerEl.outerHeight(true);
	const windowHeight = $svjq(window).height();

	if (footerBottomPos < windowHeight) {
		footerEl[0].style.marginTop = windowHeight - footerBottomPos + 'px';
	}
	footerEl.addClass('show')
})