export default null;
const $ = $svjq;

$(function() {
	const $sliderContainer = $('.toxic-slider');
	const $slides = $('.toxic-slider > .sv-layout');
	const slidesCount = $slides.length;
	let activeSlide = 0;
	let looper = null;

	// show first slide
	$slides.first().addClass('show')

	if (slidesCount > 1) {
		$sliderContainer.append('<div class="prev toxic-slider-control"></div>')
		$sliderContainer.append('<div class="next toxic-slider-control"></div>')

		$('.toxic-slider-control').on('click', e => {
			if (e.target.className.indexOf('next') > -1) {
				changeSlide(1)
			}
			else {
				changeSlide(-1)
			}
		})

		//$('body').on('touchstart touchend', e => {
		//	let lastPos = null;

		//	if ($(e.target).closest('.toxic-slider').length) {
		//		let currPos = e.originalEvent.changedTouches[0].pageX;

		//		if (currPos > (lastPos + 10)) {
		//			changeSlide(1)
		//		}

		//		lastPos = e.originalEvent.changedTouches[0].pageX;
		//	}
		//})

		// rotate
		looper = setInterval(rotate, 4000)
	}	
		
	function changeSlide(increase) {
		activeSlide += increase;

		if (activeSlide === slidesCount)
			activeSlide = 0;
		else if (activeSlide === -1)
			activeSlide = slidesCount - 1;

		$slides.removeClass('show')
		$slides.eq(activeSlide).addClass('show')

		// reset timer
		clearInterval(looper)
		looper = setInterval(rotate, 4000)
	}

	function rotate() {
		if ($('.toxic-slider:hover').length === 0) {
			changeSlide(1)
		}
	}
})