export default null;

// MAY THE FORCE BE WITH YOU

const $ = $svjq;
const mobileBreakpoint = 801;
let viewportWidth, header, blueMainMenu, whiteMenuBar, whiteMenuBarHeight;
let headerIsFixed = false;
let lastScrollPos = 0;
let debounce = false;

function init() {
  header = $('header')[0];
  blueMainMenu = $('.blue-main-menu');
  whiteMenuBar = $('.white-menu-bar');
  whiteMenuBarHeight = whiteMenuBar.outerHeight(true);
  viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (!$('header').hasClass('mobile-menu-toggled')) {
    $('body > .sv-layout').css({'padding-top': $('header').outerHeight(true) + 'px'})
  }
  $('body > .sv-layout').addClass('show')

  $('.sv-top-menu__item--active').addClass('current-page-menu-item')
}

function setMegaMenuPos() {
  const blueMainMenuBottomPos = header.getBoundingClientRect().bottom;
  $('.mega-menu').css({'top': blueMainMenu.outerHeight() + whiteMenuBar.outerHeight() + 'px'}) 
}

function copySubMenusToMobileMenu() {
  $('.top-menu').find('a').each((i, el) => {
    if (el.innerHTML.toLowerCase() === 'skola') {
      $('#school-menu').clone().attr('id', 'school-menu-mobile').insertAfter(el)
    }
    else if (el.innerHTML.toLowerCase().indexOf('retag') > -1) {
      $('#company-menu').clone().attr('id', 'company-menu-mobile').insertAfter(el)
    }
    else if (el.innerHTML.toLowerCase().indexOf('tillbe') > -1) {
      $('#accessories-menu').clone().attr('id', 'accessories-menu-mobile').insertAfter(el)
    }
  })
}

$(window).on('resize', _ => {
  init()
});

$(() => {
  init()
  copySubMenusToMobileMenu()
  
  // show mega menus
  $('.sv-top-menu__item > a').on('click', e => {
    $('.mega-menu.active').removeClass('active')

    if ($(e.target).parent('.mega-menu-toggled').length) {
      e.preventDefault()
      $('.sv-top-menu__item--active').removeClass('sv-top-menu__item--active')
      $('.mega-menu-toggled').removeClass('mega-menu-toggled')
      $('.current-page-menu-item').addClass('sv-top-menu__item--active')
      return;
    }

    $('.sv-top-menu__item--active').removeClass('sv-top-menu__item--active')    
    $('.mega-menu-toggled').removeClass('mega-menu-toggled')

    if (e.target.innerHTML.toLowerCase() === 'skola') {
      e.preventDefault()
      setMegaMenuPos()
      $('#school-menu').toggleClass('active')
      $(e.target).next('.mega-menu').addClass('active')

      if (viewportWidth <= mobileBreakpoint) {
        $('html').addClass('no-scroll')
      }

      $(e.target).closest('.sv-top-menu__item').addClass('mega-menu-toggled')
    }
		else if (e.target.innerHTML.toLowerCase().indexOf('retag') > -1) { // fucking encodings...
			e.preventDefault()
      setMegaMenuPos()
      $('#company-menu').toggleClass('active')
      $(e.target).next('.mega-menu').addClass('active')

      if (viewportWidth <= mobileBreakpoint) {
        $('html').addClass('no-scroll')
      }

      $(e.target).closest('.sv-top-menu__item').addClass('mega-menu-toggled')
    }
    else if (e.target.innerHTML.toLowerCase().indexOf('tillbe') > -1) { // fucking encodings...
      e.preventDefault()
      setMegaMenuPos()
      $('#accessories-menu').toggleClass('active')
      $(e.target).next('.mega-menu').addClass('active')
      
      if (viewportWidth <= mobileBreakpoint) {
        $('html').addClass('no-scroll')
      }

      $(e.target).closest('.sv-top-menu__item').addClass('mega-menu-toggled')
    }
  })

  $('.mega-menu').find('h4').on('click', e => {
    const item = $(e.target);
    const isToggled = item.hasClass('toggled');

    $('.mega-menu .toggled').removeClass('toggled')
    item.toggleClass('toggled', !isToggled)
    item.next().toggleClass('toggled', !isToggled)
  })

  // hide mega menu if click outside
  $(document).on('click', e => {
    if (!$(e.target).parent().hasClass('sv-top-menu__item') && !$(e.target).closest('.mega-menu').length) {
     $('.mega-menu.active').removeClass('active')
   }
 });

  // mobile menu toggler
  $('#mobile-menu-toggler').on('click', e => {
    $('.top-menu').toggleClass('active')
    $('header').addClass('mobile-menu-toggled')
    $('header').toggleClass('mobile-menu-toggled', $('.top-menu').hasClass('active'))
    $('html').toggleClass('no-scroll', $('.top-menu').hasClass('active'))
    $('.mega-menu .toggled').removeClass('toggled')
    $('.mega-menu-toggled').removeClass('mega-menu-toggled')
    $('.current-page-menu-item').addClass('sv-top-menu__item--active')
  })

  $('.sub-menu-links a[href*="' + window.location.pathname + '"]').addClass('selected')

  // fixed header
  $(window).scroll(e => {

    if (!debounce) {
      debounce = true;

      setTimeout(_ => {
        debounce = false;
      }, 100)
    }
    else {
      return;
    }

    const currentScrollPos = e.currentTarget.pageYOffset >= 0 ? e.currentTarget.pageYOffset : 0;
    let showWhiteMenu = currentScrollPos <= whiteMenuBarHeight || !lastScrollPos || currentScrollPos <= lastScrollPos;
    
    if (showWhiteMenu) {
      $('header').css('transform', 'translateY(0)')
    }
    else {      
      $('header').css('transform', 'translateY(-' + whiteMenuBarHeight + 'px)')
    }

    if (viewportWidth > mobileBreakpoint) {
      $('.mega-menu.active').removeClass('active')
    }

    if (viewportWidth > mobileBreakpoint) {
      $('.mega-menu-toggled').removeClass('mega-menu-toggled')
      $('.current-page-menu-item').addClass('sv-top-menu__item--active')
    }

    lastScrollPos = currentScrollPos;
  })  
})

